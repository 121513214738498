import {
  GetProfileInformationRequest,
  GetProfileInformationResponse
} from '@winestyle/api-client/src/ts/api/customer/v1/customer_profile_api_pb'

import type { CustomerProfileAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_profile_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export const customerProfileApi = (customerProfileAPIClient: CustomerProfileAPIPromiseClient) => {
  async function getProfileInformation () {
    const request: ReqTypeWithResponseCases<GetProfileInformationRequest> = new GetProfileInformationRequest()

    request.responseCases = GetProfileInformationResponse.ResponseCase
    // captchaCode && request.setCaptchaCode(captchaCode)

    const res = await customerProfileAPIClient.getProfileInformation(request)
    return res.getData()?.toObject() || {}
  }
  return {
    getProfileInformation
  }
}
