import {
  GetHomeProductCollectionsRequest,
  GetBestsellerProductCollectionsRequest,
  GetCatalogProductCollectionsRequest,
  GetProductCollectionsForProductPageRequest,
  GetCartProductCollectionsRequest
} from '@winestyle/api-client/src/ts/api/catalog/v1/product_collections_api_pb.js'
import { SectionPath } from '@winestyle/api-client/src/ts/api/catalog/v1/common_pb.js'

import type { ProductCollectionAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/product_collections_api_grpc_web_pb'
import type { ProductCollection } from '../models/ProductCollection'

export type ProductCollectionAPI = {
  getBestsellersCollection: () => Promise<ProductCollection[] | undefined>
  getHomeProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
  getBestsellerProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
  getCatalogProductCollectionsRequest: (sectionPath?: string) => Promise<ProductCollection[] | undefined>
  getProductCollectionsForProductPageRequest: (productCode?: string) => Promise<ProductCollection[] | undefined>
  getCartProductCollectionsRequest: () => Promise<ProductCollection[] | undefined>
}

export const productCollectionAPI = (productCollectionAPIClient: ProductCollectionAPIPromiseClient): ProductCollectionAPI => {
  async function getBestsellersCollection () {
    const request: GetBestsellerProductCollectionsRequest = new GetBestsellerProductCollectionsRequest()
    const res = await productCollectionAPIClient.getBestsellerProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getHomeProductCollectionsRequest () {
    const request: GetHomeProductCollectionsRequest = new GetHomeProductCollectionsRequest()
    const res = await productCollectionAPIClient.getHomeProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getBestsellerProductCollectionsRequest () {
    const request: GetBestsellerProductCollectionsRequest = new GetBestsellerProductCollectionsRequest()
    const res = await productCollectionAPIClient.getBestsellerProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  async function getCatalogProductCollectionsRequest (sectionPath?: string) {
    const request: GetCatalogProductCollectionsRequest = new GetCatalogProductCollectionsRequest()
    const section = new SectionPath()

    if (sectionPath) { section.setPath(sectionPath) }
    request.setSection(section)

    const res = await productCollectionAPIClient.getCatalogProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }
  async function getProductCollectionsForProductPageRequest (productCode?: string) {
    const request: GetProductCollectionsForProductPageRequest = new GetProductCollectionsForProductPageRequest()

    if (productCode) { request.setProductCode(productCode) }

    const res = await productCollectionAPIClient.getProductCollectionsForProductPage(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }
  async function getCartProductCollectionsRequest () {
    const request: GetCartProductCollectionsRequest = new GetCartProductCollectionsRequest()
    const res = await productCollectionAPIClient.getCartProductCollections(request)

    return res?.getData()?.getProductCollectionsList?.()?.map?.(item => item.toObject()) as ProductCollection[]
  }

  return {
    getBestsellersCollection,
    getHomeProductCollectionsRequest,
    getBestsellerProductCollectionsRequest,
    getCatalogProductCollectionsRequest,
    getProductCollectionsForProductPageRequest,
    getCartProductCollectionsRequest
  }
}
